import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './homepage.css'

import { Typography } from '@mui/material';
function HomePage() {
  useEffect(() => {
    const container = document.getElementById('home');

    const handleMouseMove = e => {
      const circle = document.querySelector('.outer-circle');
      const containerRect = container.getBoundingClientRect();
      const mouseX = e.clientX - containerRect.left;
      const mouseY = e.clientY - containerRect.top;
      circle.style.left = mouseX + 'px';
      circle.style.top = mouseY + 'px';
    };

    container.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  return (
    <div>
        <Container relative disableGutters className="contant" id='home'>
        <Box className='home-text'>
           {/* <Typography className='hello EMPOWERING' variant='h1'>EMPOWERING</Typography> */}
           <Box className='Daniyal'>
           <Typography className='hello EMPOWERING letter' variant='h1'>E</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>M</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>P</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>O</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>W</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>E</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>R</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>I</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>N</Typography>
           <Typography className='hello EMPOWERING letter' variant='h1'>G</Typography>
           
           
           </Box>
           
           <Typography className='hello strick' variant='h3'>Through</Typography>
           {/* <Typography className='hello' variant='h2'>Technology</Typography> */}
           <Box className='Daniyal'>
           <Typography className='hello EMPOWERING letter' variant='h2'>T</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>e</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>c</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>h</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>n</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>o</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>l</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>o</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>g</Typography>
           <Typography className='hello EMPOWERING letter' variant='h2'>y</Typography>
           </Box>
        </Box>
        <div class="outer-circle">
  <div class="inner-circle"></div>
</div>
      </Container>
        
    </div>
  )
}

export default HomePage