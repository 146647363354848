import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, Link,Box } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import './footers.css'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#136BB2 !important',
    color: '#fff',
    padding: theme.spacing(6, 0),
  },
  companyName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    marginRight: theme.spacing(2),
    color:'white',
  },
}));

const Footers = () => {
    const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" className={classes.companyName}>
            Awami System Technologies (AST)
            </Typography>
            <Typography variant="body1">
            Empowering Through Technology
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="flex-end" className='follow-us-grid'>
            <Grid item xs={12} md={12} container className='follow-us-text'>
              <Typography variant="h5">Follow Us</Typography>
            </Grid>
            <Grid item xs={12} md={12} container className='social-icon'>
              <Link href="https://www.facebook.com" target="_blank">
                <FacebookIcon
                  fontSize="large"
                  className={classes.socialIcon}
                />
              </Link>
              <Link href="https://www.twitter.com" target="_blank">
                <TwitterIcon
                  fontSize="large"
                  className={classes.socialIcon}
                />
              </Link>
              <Link href="https://www.instagram.com" target="_blank">
                <InstagramIcon
                  fontSize="large"
                  className={classes.socialIcon}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footers