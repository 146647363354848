import { Typography } from '@mui/material'
import { Container,Box} from '@mui/system'
import React from 'react'
import { styled } from '@mui/material/styles';
import './services.css'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PaletteIcon from '@mui/icons-material/Palette';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import BOutSource from '../BusnessOutSource/BOutSource';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Services = () => {
    return ( 
    <>
    <Container relavent className='services-main'>
        <Box id="infoout">
    <Typography variant='h3' className='services-heading'>
    Information Technology Services
    </Typography>
    <Typography variant='body1' className='services-info'>
    We operate as One Stop Shop for our customers and provide end-to-end solutions.
    Starting from feasibility studies, passing through the analysis, process simplification & re-engineering,
    system design, and development for successful implementation are the primary services. Infrastructure management,
    network management, database management, etc. are also provided on the systems implemented by us and for those 
    already developed & implemented
    </Typography>
        </Box>
        <Grid container spacing={2} className='grid-card'>
        <Grid item xs={6} sm={3}>
          <Item className='cardBox'>
            <Card className='services-cardss'>
            <CardContent>
                <PhoneIphoneIcon fontSize="large" className='app-logo' />
      <Typography variant='h6' className='app-card' gutterBottom>
      App Development
      </Typography>
      <Typography variant="body2" component="div" className='card-desp'>
      Meticulous attention to app functionality and user experience.
      </Typography>
      
    </CardContent>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={6} sm={3} >
          <Item className='cardBox'><Card className='services-cardss'>
            <CardContent>
                <LaptopMacIcon fontSize="large" className='app-logo' />
      <Typography variant='h6' className='app-card' gutterBottom>
      Web Development
      </Typography>
      <Typography variant="body2" component="div" className='card-desp'>
      Meticulous attention to Web functionality and user experience.
      </Typography>
      
    </CardContent>
            </Card></Item > 
        </Grid>
        <Grid item xs={6} sm={3}>
          <Item className='cardBox'>
          <Card className='services-cardss'>
            <CardContent>
                <PaletteIcon fontSize="large" className='app-logo' />
      <Typography variant='h6' className='app-card' gutterBottom>
      Design Studio
      </Typography>
      <Typography variant="body2" component="div" className='card-desp'>
      Hook your potential customers with awesome website designs.
      </Typography>
      
    </CardContent>
            </Card>

          </Item>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Item className='cardBox'>
          <Card className='services-cardss'>
            <CardContent>
                <MilitaryTechIcon fontSize="large" className='app-logo' />
      <Typography variant='h6' className='app-card' gutterBottom>
      Quality Assurance
      </Typography>
      <Typography variant="body2" component="div" className='card-desp'>
      Thriving businesses with our cost effective, error free  solutions.
      </Typography>
      
    </CardContent>
            </Card>
          </Item>
        </Grid>
      </Grid>
      
    </Container>
    <Box className='help'>
            <Typography variant='h4' className='help-text'>
                We Are Here To Help You 
                <Button className='con-button'>Contact <ArrowForwardIcon className='farward-icon' />
                </Button>
            </Typography>
        </Box>
        <Container>
        <BOutSource/>
        </Container>
    
    </>
    
    )
}

export default Services