import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Container,Box } from '@mui/system';
import './backtotop.css';



  
const BackToTop = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) { // Show button after user has scrolled down 300px
        setIsDisplayed(true);
      } else {
        setIsDisplayed(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Container relavent>
        <Box>
        {isDisplayed && (
        <button className="back-to-top-button" onClick={handleClick}>
          <KeyboardArrowUpIcon fontSize="large"/>
        </button>
      )}
        </Box>
    </Container>
  )
        }

export default BackToTop