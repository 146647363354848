import { Typography } from '@mui/material'
import { Container,Box } from '@mui/system'
import React from 'react'
import './boutsource.css'
import Card from '@mui/material/Card';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CardContent from '@mui/material/CardContent';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import MovingIcon from '@mui/icons-material/Moving';
import Divider from '@mui/material/Divider';

const BOutSource = () => {
  return (
    <>
    
    <Container relavent className='outmain' id='outsource'>

        <Box>
            <Typography variant='h3' className='out-heading'>
            Business Process Outsourcing
            </Typography>
            <Typography variant='body1' className='out-tagline'>
            Call Centre,Technical Support,Client Success, document imagining/ management, archival, and data entry are provided as data readiness
            and post-implementation support services. We also provide other related services like change management,
            training, and capacity building which are essential for sustainable system implementations.
            </Typography>
        </Box>
        <Box className="container aboutus-cardss">
  <Box className="card">
    <Card class="face face1">
      <CardContent class="content">
        
      <SettingsApplicationsIcon fontSize="large"  className='out-logo-con'/>
      <Typography  variant='h4' gutterBottom className='ser-heading'>
      Technical Support
      </Typography>
      <Divider variant="middle"  className='out-divider'/>
      </CardContent>
    </Card>
    <Card class="face face2">
      <CardContent class="content">
      <Typography variant="body1" component="div">
      The perfect customer centric solution for all your applications and systems running onsite or remote.
      </Typography>
      </CardContent>
    </Card>
  </Box>
  <Box class="card">
    <Card class="face face1">
      <CardContent class="content">
        
      <SupportAgentIcon fontSize="large" className='out-logo-con' />
      <Typography  variant='h4' gutterBottom className='ser-heading'>
      Call Center
      </Typography>
      <Divider variant="middle"  className='out-divider'/>
      </CardContent>
    </Card>
    <Card class="face face2">
      <CardContent class="content">
      <Typography variant="body1" component="div">
      Uplift your brand image, extent into new markets and maintain strong customer support with the help of our skilled agents.
      </Typography>
      </CardContent>
    </Card>
  </Box>
  <Box class="card">
    <Card class="face face1">
      <CardContent class="content">
       
      <MovingIcon fontSize="large" className='out-logo-con'/>
      <Typography variant='h4' gutterBottom className='ser-heading'>
      Client Success
      </Typography>
      <Divider variant="middle"  className='out-divider'/>
      </CardContent>
    </Card>
    <Card class="face face2">
      <CardContent class="content">
      <Typography variant="body1" component="div">
      Empasize on building stronger relationship with you'r clientele and improve the implementation of your products/services.
      </Typography>
      </CardContent>
    </Card>
  </Box>
</Box>
    </Container>
    </>
  )
}

export default BOutSource