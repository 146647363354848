import React,{useState} from 'react';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import './navbar.css'
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { Link, animateScroll as scroll, scroller } from 'react-scroll';
import Projects from '../Projects/Projects';
import Services from '../Services/Services';
import AboutUS from '../AboutUS/AboutUS';
import HomePage from '../HomePage/HomePage';
import Values from '../Values/Values';
import ContactUs from '../ContactUS/ContactUs';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));
const NavBar = () => {
  const handleClick = (event) => {
    event.preventDefault();
    scroller.scrollTo('home','aboutus','corevalue','infoout','outsource','sector-spec','project','Certificates','contact', {
      duration: 800,
      delay: 0,
      
      scroll:'smooth'
    });
  };
  
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
<AppBar position="static" className='main-toolbar'>
      <Toolbar className='toolbar-navbar'>
        <Typography variant="h6" className={classes.title}>
        <Link to="home" smooth={true} duration={500} onClick={handleClick}>
        <Box
            component="img"
            sx={{
            height: 64,
            display: { xs: 'block', md: 'flex' },
            mx: 3
            }}
            alt="Your logo."
            src='./images/logo.png'
        />
        </Link>
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <Link to="aboutus" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >About US</MenuItem>
              </Link>
              <Link to="corevalue" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Core Values</MenuItem>
              </Link>
              <Link to="infoout" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Services</MenuItem>
              </Link><Link to="project" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Projects</MenuItem>
              </Link>
              <Link to="Certificates" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Certificates</MenuItem>
              </Link>
            </Menu>
          </>
        ) : (
          <>
            <Link to="aboutus" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >About US</MenuItem>
              </Link>
              <Link to="corevalue" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Core Values</MenuItem>
              </Link>




              <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        Services 
        <ExpandMoreSharpIcon/>
      </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
              >
                <Link to="infoout" smooth={true} duration={500} onClick={handleClick}>
                <MenuItem onClick={handleMenuClose}>Information Technology</MenuItem>
                </Link>
                <Link to="outsource" smooth={true} duration={500} onClick={handleClick}>
                <MenuItem onClick={handleMenuClose}>Business Outsourcing</MenuItem>
                </Link>
                <Link to="sector-spec" smooth={true} duration={500} onClick={handleClick}>
                <MenuItem onClick={handleMenuClose}>Sectoral Specialisation</MenuItem>
                </Link>
              </Menu>
              {/* <Link to="infoout" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Services</MenuItem>
              </Link> */}
              <Link to="project" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Projects</MenuItem>
              </Link>
              <Link to="Certificates" smooth={true} duration={500} onClick={handleClick}>
              <MenuItem >Certificates</MenuItem>
              </Link>
              <Link to="contact" smooth={true} duration={500} onClick={handleClick}>
              
               <Button className='btn-contact'>Contact Us</Button> 
              </Link>
          </>
        )}
      </Toolbar>
    </AppBar>
  </React.Fragment>
  )
}

export default NavBar


