import React from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, Typography,CardActions } from '@material-ui/core';
import { Container,Box } from '@mui/system';
import { Button } from '@material-ui/core';
import './projects.css'
const useStyles = makeStyles((theme) => ({
  card: {
    width: '300px',
    margin: '0 50px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '3rem !important',
    '@media (max-width: 768px)':{
      margin: '0',
    }
  },
  media: {
    height: '200px',
    backgroundSize: '100% 100% !important',
    borderBottom:'3px solid'
   
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '10px',
    
  },
  body: {
    fontSize: '14px',
    color:'white'
  },
}));
const Projects = ({ data }) => {
  const classess = useStyles();

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
   
    
    
    
    
    
  };

  return (
    <Container relative className='project-main' id='project'>
      <Typography variant='h3' className='project-heading'>
        Our Projects
      </Typography>
      <Typography variant='body1' className='project-body'>
      From concept to completion, browse our portfolio of successful projects and see how we can bring your vision to life
      </Typography>
      <Box className='project-box'>

    <Slider {...settings1}>
    {data.map((item, index) => (
      <div key={index}>
        <Card className={classess.card}>
          <CardMedia className={classess.media} image={item.image} />
          <CardContent className='card-contant'>
            <Typography className={classess.title}>{item.title}</Typography>
            <Typography className={classess.body}>{item.body}</Typography>
          </CardContent>
        </Card>
      </div>
    ))}
  </Slider>
      </Box>
    </Container>
  );
}

export default Projects