import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import RocketRoundedIcon from '@mui/icons-material/RocketRounded';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CardContent from '@mui/material/CardContent';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import './aboutus.css'

const AboutUS = () => {
  return (
    <>
   
     <Container relative className='about-us-main' id='aboutus'>
      
        <Box className='box'>
            <Typography variant='h3' className='heading'>
            About Us
            </Typography>
            <Typography variant="body1" gutterBottom className='about-text'>
            Awami Systems Technologies (AST) provides end-to-end solutions related to Information Technology to our customers,
            which makes us One-Stop solution provider. AWAMI is an Urdu word, which means “People’s”; our focus at Awami Systems 
            is to implement IT Solutions for Security & Facilitation of the citizen and provide enabling environment of 
            E-Governance for public and private sector organizations dealing with the citizens and customers. 

      </Typography>
      <Typography variant="body1" gutterBottom className='about-text'>
      We believe that <strong>“for every problem, there is a solution”</strong> and we provide <strong>“IT Solutions”</strong> up to entire satisfaction of our customers.
      </Typography>
        </Box>
        <Box className='aboutus-cards'>
      <Card variant="outlined" className='Card zoom'>
        <CardContent>
        <RocketRoundedIcon fontSize="large" />
      <Typography  variant='h4' gutterBottom>
      Our Mission
      </Typography>
      <Typography variant="body1" component="div">
      We have experienced that the users of IT Systems want to be empowered,
      instead of being just IT followers. Therefore, we are determined to “Empowering through Technology
      </Typography>
      
     
    </CardContent>
      </Card>
      <Card variant="outlined" className='Card zoom'>
        <CardContent>
        <WbIncandescentOutlinedIcon fontSize="large" />
      <Typography  variant='h4' gutterBottom>
        Our Vision
      </Typography>
      <Typography variant="body1" component="div">
      Our vision is to be a leader in our industry by providing innovative solutions and exceptional customer experiences that exceed expectations.      </Typography>
      
      
    </CardContent>
      </Card>
      <Card variant="outlined" className='Card zoom'>
        <CardContent>
        <QueryBuilderIcon fontSize="large" />
      <Typography variant='h4' gutterBottom>
      Our Values
      </Typography>
      <Typography variant="body1" component="div">
      We believe that leadership is the key to success. Under the able leadership; teams nurture, 
      bring innovation and deliver quality products with professional excellence through best coordination with customers.
      </Typography>
    </CardContent>
      </Card>
    </Box>
      </Container>
    </>
  )
}

export default AboutUS