import { Typography } from '@mui/material'
import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './values.css'
const Values = () => {
  return (
    <>
    <Container relative className='core-value-main' id='corevalue'>
        <Box className='core-box'>
            <Typography variant='h3' className='headingcore'>
                Our Core Values
            </Typography>
            <Typography variant='body1' className='core-tagline'>
            Our core values include integrity, excellence, innovation, and teamwork, which drive our commitment to delivering the best possible products and services to our clients.
            </Typography>
        </Box>
        <Box className='core-image'></Box>
   </Container>
    </>
  )
}

export default Values