import { Typography } from '@mui/material'
import { Container,Box } from '@mui/system'
import './contact.css'
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@mui/material/Divider';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 5rem',
    
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
      
    },
  },
  '@media (max-width: 600px)':{
    root: {
      padding: '0px 1rem',
      
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
        
      },
    },
  }
  
}));
const ContactUs = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Name: ${name}, Email: ${email}, Phone: ${phone}, Subject: ${subject}, Message: ${message}`);
    // Add code to submit form data to server or send email
    toast.success('Form submitted successfully!', {
      position: toast.POSITION.TOP_CENTER
    });

    
    setName('');
    setEmail('');
    setPhone('');
    setSubject('');
    setMessage('');
  };
  
  return (
    <Container relative className='contact-main' id='contact'>
       
        <Box className='contact-box'>
            <Typography variant='h3' className='contact-heading'>
                Contact Us
            </Typography>
            <Typography variant='body1' className='contact-text'>
            We would love to hear from you - get in touch with us through our contact form or reach out to us directly via email or phone.
            </Typography>
            <Box className='form-box'>

        <form className={classes.root} onSubmit={handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
          className='email'
        />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <TextField
          label="Phone"
          variant="outlined"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />
        <TextField
          label="Subject"
          variant="outlined"
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          required
          className='subject'
        />
      </div>
      <div>
        <TextField
          label="Message"
          variant="outlined"
          multiline
          minRows={6}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          required
          fullWidth
        />
      </div>
      <Box className='box-button'>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className="formButton"
      >
        Submit
      </Button>

      </Box>
    </form>
            </Box>
    <Divider className='footer-divider' />
    <Box className='footer'>
        <Typography variant='h6' className='footer-contant'><LocationOnOutlinedIcon fontSize='large'/>12-MB City Mall Plaza, First Floor, I-8 Markaz, Islamabad - Pakistan  <Divider className='divider' orientation="vertical" flexItem />  <PhoneOutlinedIcon fontSize='large'/> (+9251) 844-8730 </Typography>
        <Typography variant='h6' className='footer-contant'><MailOutlineIcon fontSize='large'/> info@ast.com.pk</Typography>
      </Box>
      <ToastContainer />
        </Box>
    </Container>
  )
}

export default ContactUs