import { Typography } from '@mui/material'
import { Container,Box } from '@mui/system'
import './sectorspec.css'
import React from 'react'
const SectorSpec = () => {
  return (
    <>
         <Box className='help tec' id="sector-spec">
            <Typography variant='h4' className='help-text'>
            Empower Yourself Through Technology With Us
            </Typography>
        </Box>
    
    <Container ralative className='sector-main'  id="">
        <Box>
           <Typography variant='h3' className='sector-heading'>
            Sectoral Specialisation
            </Typography> 
            <Typography variant='body1' className='body-text'>
            Explore our diverse sectoral specializations and discover how our expertise can help drive your business forward.
            </Typography>
            
        </Box>
       <Box className='sectoral-work-tree'></Box>
    </Container>
    </>
  )
}

export default SectorSpec