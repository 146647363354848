import NavBar from '../src/components/NavBar/NavBar'
import AboutUS from './components/AboutUS/AboutUS';
import BackToTop from './components/BackToTop/BackToTop';
import Certificates from './components/Certificates/Certificates';
import ContactUs from './components/ContactUS/ContactUs';
import Footers from './components/Footers/Footers';
import HomePage from './components/HomePage/HomePage';
import Patners from './components/Patners/Patners';
import Projects from './components/Projects/Projects';
import SectorSpec from './components/SectorSpec/SectorSpec';
import Services from './components/Services/Services';
import Values from './components/Values/Values';

function App() {
  const data = [
    {
      image: './images/Slideimage.jpeg',
      title: 'Animal Disease Diagnostic Reporting and Surveillance System',
      // body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      image: './images/lims.jpg',
      title: 'LIMS – Livestock Initiative Management System',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/bills.jpg',
      title: 'Smart Utility Management System (SUMS)',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/camp.jpg',
      title: 'Campus Management System',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/income.jpg',
      title: 'MyTax – E-Filing System',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    
   
    {
      image: './images/farm.jpg',
      title: 'Farmer Registration System',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    
    {
      image: './images/injectionn.jpg',
      title: 'Animal Vaccination System ',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/animal-farms.jpg',
      title: 'Animal Census System ',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
   
    {
      image: './images/slat.jpg',
      title: 'Slaughter Management System for PAMCO ',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/piu.png',
      title: 'PIU – Punjab (Project Monitoring Dashboard & Mobile APPs)',
      // body: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      image: './images/per.jpg',
      title: 'Performance Monitoring System',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/geo.jpg',
      title: 'Field Inspection and reporting system (Geo- Tag)',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/dashboard.jpg',
      title: 'Executive Dashboard & MIS',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/web.jpg',
      title: 'Website Development for CPPA',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/Webinar.jpg',
      title: 'Webinar Management for SAARC Energy',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/Call.jpg',
      title: '24/7 Contact and Communication Center',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/database.jpg',
      title: 'Database Management and Operational Support',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    {
      image: './images/pump.jpg',
      title: 'ERP Solution for Petrol Pump Management – WAPAD Foundation',
      // body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco .',
    },
    
    
    
  ];
  return (
    <>
    <NavBar/>
    <HomePage/>
    <AboutUS/>
    <Values/>
    <Services/>
    <SectorSpec/>
    <Projects  data={data}/>
    <Patners/>
    <Certificates/>
    <ContactUs/>
    <BackToTop/>
    <Footers/>
    </>
  );
}

export default App;
